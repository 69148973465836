// src/components/StickerOrderForm.js
import React, { useState } from 'react';
import {
  Container,
  Box,
  Button,
  Typography,
  useMediaQuery,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Fab
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@mui/material/styles';

const StickerOrderForm = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  // For simplicity, define your styles here
  const predefinedStyles = [
    'Anime',
    'Vintage Cartoon',
    'Vintage Cartoon Black and White',
    'Monet Painting',
    'Van Gogh Painting'
  ];

  // Local states for uploads and error messages
  const [uploads, setUploads] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // Handle file uploads
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const newUploads = files.map(file => ({
      file,
      preview: URL.createObjectURL(file),
      style: '',
      quantity: 1
    }));
    setUploads(prev => [...prev, ...newUploads]);
  };

  // Handle style changes
  const handleUploadStyleChange = (index, newStyle) => {
    const newUploads = [...uploads];
    newUploads[index].style = newStyle;
    setUploads(newUploads);
  };

  // Delete an upload
  const handleDeleteUpload = (index) => {
    setUploads(prev => prev.filter((_, i) => i !== index));
  };

  // Generate stickers (placeholder logic)
  const handleGenerateStickers = async () => {
    // Ensure a style is chosen for each image
    for (let i = 0; i < uploads.length; i++) {
      if (!uploads[i].style) {
        alert(`Please select a style for image ${i + 1}.`);
        return;
      }
    }
    setLoading(true);
    try {
      // Placeholder fetch call
      await new Promise(res => setTimeout(res, 1500));
      alert('Stickers generated successfully!');
    } catch (err) {
      setError('Error generating stickers');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 2 }}>
      {uploads.length > 0 && (
        <>
          {isSmall ? (
            // Mobile: stacked layout
            <Box>
              {uploads.map((upload, index) => (
                <Box
                  key={index}
                  sx={{
                    border: '1px solid #555555',
                    borderRadius: 1,
                    p: 2,
                    my: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}
                >
                  <Box
                    component="img"
                    src={upload.preview}
                    alt="Preview"
                    sx={{ width: '300px', height: 'auto', borderRadius: 1 }}
                  />
                  <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                    <InputLabel>Style</InputLabel>
                    <Select
                      value={upload.style}
                      onChange={(e) => handleUploadStyleChange(index, e.target.value)}
                      label="Style"
                    >
                      {predefinedStyles.map((s) => (
                        <MenuItem key={s} value={s}>
                          {s}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <IconButton
                    onClick={() => handleDeleteUpload(index)}
                    sx={{ mt: 2, '&:hover': { color: '#ffeb3b' } }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              ))}
            </Box>
          ) : (
            // Desktop: table layout with gradient border around the container
            <TableContainer
              component={Paper}
              sx={{
                mt: 2,
                backgroundColor: '#3C3C3C',
                boxShadow: 'none',
                border: '2px solid',
                borderImage: 'linear-gradient(45deg, #9c27b0, #ffeb3b) 1'
              }}
            >
              <Table size="small" sx={{ tableLayout: 'fixed', width: '100%' }}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        padding: '8px',
                        width: '60%',
                        borderBottom: 'none',
                        fontStyle: 'italic'
                      }}
                    >
                      Image
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        padding: '8px',
                        width: 'calc(40% - 40px)',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                        overflowWrap: 'break-word',
                        borderBottom: 'none',
                        fontStyle: 'italic'
                      }}
                    >
                      Style
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ width: '40px', padding: '8px', borderBottom: 'none' }}
                    />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {uploads.map((upload, index) => (
                    <TableRow key={index} sx={{ borderBottom: 'none' }}>
                      <TableCell
                        align="center"
                        sx={{ padding: '8px', width: '60%', borderBottom: 'none' }}
                      >
                        <Box
                          component="img"
                          src={upload.preview}
                          alt="Preview"
                          sx={{
                            width: '300px',
                            height: 'auto',
                            borderRadius: 1
                          }}
                        />
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          padding: '8px',
                          width: 'calc(40% - 40px)',
                          whiteSpace: 'normal',
                          wordBreak: 'break-word',
                          overflowWrap: 'break-word',
                          borderBottom: 'none'
                        }}
                      >
                        <FormControl variant="outlined" fullWidth sx={{ width: '100%' }}>
                          <InputLabel>Style</InputLabel>
                          <Select
                            value={upload.style}
                            onChange={(e) => handleUploadStyleChange(index, e.target.value)}
                            label="Style"
                          >
                            {predefinedStyles.map((s) => (
                              <MenuItem key={s} value={s}>
                                {s}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ width: '40px', padding: '8px', borderBottom: 'none' }}
                      >
                        <IconButton
                          onClick={() => handleDeleteUpload(index)}
                          sx={{ '&:hover': { color: '#ffeb3b' } }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      )}
      {error && (
        <Typography color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}

      {/* Add Image FAB */}
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
        <Fab
          component="label"
          aria-label="add"
          sx={{
            width: 64,
            height: 64,
            bgcolor: uploads.length > 0 ? theme.palette.secondary.main : theme.palette.primary.main,
            '&:hover': {
              bgcolor: uploads.length > 0 ? theme.palette.secondary.dark : theme.palette.primary.dark
            }
          }}
        >
          <AddIcon sx={{ fontSize: 32 }} />
          <input type="file" hidden accept="image/*" multiple onChange={handleFileChange} />
        </Fab>
        <Typography variant="subtitle1" sx={{ mt: 1 }}>
          {uploads.length > 0 ? 'Add more images' : 'Add Your Image'}
        </Typography>
      </Box>

      {/* Generate Stickers button */}
      {uploads.length > 0 && (
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2, padding: '16px', fontSize: '1.2rem' }}
          onClick={handleGenerateStickers}
          disabled={loading}
        >
          {loading ? 'Generating Stickers...' : 'Generate Stickers'}
        </Button>
      )}
    </Container>
  );
};

export default StickerOrderForm;