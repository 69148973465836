// src/components/DemoBox.js
import React from 'react';
import { Box } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

const DemoBox = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2,
        mb: 6,
        p: 3,
        border: '2px solid',
        borderImage: 'linear-gradient(45deg, #9c27b0, #ffeb3b) 1',
        borderRadius: 3,
        boxShadow: '0px 8px 16px rgba(0,0,0,0.5)',
        background: 'rgba(255, 255, 255, 0.05)',
        minHeight: { xs: '60vh', md: '30vh' }
    }}
    >
      {/* Group Original Image and Arrow */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          gap: { xs: 0, md: 2 }
        }}
      >
        {/* Original Image Container */}
        <Box sx={{ flex: 1, textAlign: 'center' }}>
          <Box
            component="img"
            src="/imgs/dog_original.png"
            alt="Original"
            sx={{
              width: '100%',
              maxWidth: '240px',
              borderRadius: 2,
              boxShadow: '0px 4px 10px rgba(0,0,0,0.5)'
            }}
          />
        </Box>
        {/* Arrow */}
        <Box sx={{ flex: 'none', display: 'flex', alignItems: 'center' }}>
          <ArrowRightAltIcon sx={{ fontSize: '4rem', color: '#ffeb3b' }} />
        </Box>
      </Box>

      {/* Sticker Images Container - More Horizontal Space */}
      <Box
        sx={{
          flex: 2,
          position: 'relative',
          width: '100%',
          maxWidth: '400px',
          height: { xs: 'auto', md: '300px' },
          ml: { xs: 0, md: 2 }
        }}
      >
        <Box
          component="img"
          src="/imgs/dog_cartoon.png"
          alt="Sticker 1"
          sx={{
            position: 'absolute',
            top: { xs: '-12%', md: '-12%' },
            left: { xs: '-25%', md: '-25%' },
            width: '75%',
            transform: `rotate(${Math.floor(Math.random() * 60) - 30}deg)`,
            borderRadius: 2
          }}
        />
        <Box
          component="img"
          src="/imgs/dog_anime.png"
          alt="Sticker 2"
          sx={{
            position: 'absolute',
            top: { xs: '25%', md: '25%' },
            left: { xs: '15%', md: '15%' },
            width: '75%',
            transform: `rotate(${Math.floor(Math.random() * 60) - 30}deg)`,
            borderRadius: 2
          }}
        />
        <Box
          component="img"
          src="/imgs/dog_black_white.png"
          alt="Sticker 3"
          sx={{
            position: 'absolute',
            top: { xs: '-20%', md: '-20%' },
            left: { xs: '50%', md: '50%' },
            width: '75%',
            transform: `rotate(${Math.floor(Math.random() * 60) - 30}deg)`,
            borderRadius: 2
          }}
        />
      </Box>
    </Box>
  );
};

export default DemoBox;