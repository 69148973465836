// src/components/Header.js
import React from 'react';
import { Typography } from '@mui/material';
import { keyframes } from '@mui/system';

const gradientShift = keyframes`
  0% { background-position: 0% 0%; }
  50% { background-position: 50% 75%; }
  50% { background-position: 150% 150%; }
  50% { background-position: 75% 50%; }
  100% { background-position: 0% 0%; }
`;

const Header = () => {
  return (
    <Typography
      align="center"
      gutterBottom
      sx={{
        fontSize: { xs: '3.7rem', md: '4.5rem' },
        fontWeight: 'bold',
        width: '100%',
        background: 'linear-gradient(150deg, #9c27b0, #ffeb3b)',
        backgroundSize: '200% 200%',
        animation: `${gradientShift} 6s linear infinite`,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        mb: 6
      }}
    >
      Enchanted Stickers
    </Typography>
  );
};

export default Header;