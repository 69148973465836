// src/components/ShippingForm.js
import React, { useState } from 'react';
import { Box, Grid, TextField, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import validator from 'validator';

const usStates = [
  "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA",
  "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD",
  "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ",
  "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC",
  "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"
];

const ShippingForm = ({
  shippingAddress = { name: '', email: '', address: '', city: '', state: '', zip: '' },
  handleAddressChange = () => {}
}) => {
  const [errors, setErrors] = useState({});

  // Validate field value based on field name
  const validateField = (name, value) => {
    let error = '';
    if (validator.isEmpty(value.trim())) {
      error = 'This field is required';
    } else {
      if (name === 'email' && !validator.isEmail(value)) {
        error = 'Invalid email address';
      } else if (name === 'state' && !/^[A-Za-z]{2}$/.test(value)) {
        error = 'State must be 2 letters';
      } else if (name === 'zip' && !validator.isPostalCode(value, 'US')) {
        error = 'Invalid ZIP code';
      }
    }
    return error;
  };

  // Validate field on blur and update errors state
  const handleBlur = (e) => {
    const { name, value } = e.target;
    const error = validateField(name, value);
    setErrors(prevErrors => ({ ...prevErrors, [name]: error }));
  };

  // Helper function: if the field is filled and valid, return a gradient border style; otherwise, return no additional style.
  const getFieldStyle = (value, error) => {
    if (value && value.trim() !== '' && !error) {
      return {
        '& .MuiOutlinedInput-notchedOutline': {
          border: '2px solid',
          borderImage: 'linear-gradient(150deg, #9c27b0 -30%, #b47cb4 50%, #ffeb3b 130%) 1'
        }
      };
    }
    return {};
  };

  return (
    <Box sx={{ mt: 4, mb: 6, textAlign: 'center' }}>
      <Typography variant="h5" gutterBottom sx={{ color: '#ffffff' }}>
        Shipping Details
      </Typography>
      <Grid container spacing={2} justifyContent="center" alignItems="center" textAlign="center">
        <Grid item xs={12} sm={10}>
          <TextField
            label="Full Name"
            name="name"
            variant="outlined"
            fullWidth
            value={shippingAddress.name}
            onChange={handleAddressChange}
            onBlur={handleBlur}
            error={!!errors.name}
            helperText={errors.name}
            sx={getFieldStyle(shippingAddress.name, errors.name)}
            InputLabelProps={{ sx: { color: '#ffffff' } }}
            InputProps={{ sx: { color: '#ffffff' } }}
          />
        </Grid>
        <Grid item xs={12} sm={10}>
          <TextField
            label="Email"
            name="email"
            variant="outlined"
            fullWidth
            value={shippingAddress.email}
            onChange={handleAddressChange}
            onBlur={handleBlur}
            error={!!errors.email}
            helperText={errors.email}
            sx={getFieldStyle(shippingAddress.email, errors.email)}
            InputLabelProps={{ sx: { color: '#ffffff' } }}
            InputProps={{ sx: { color: '#ffffff' } }}
          />
        </Grid>
        <Grid item xs={12} sm={10}>
          <TextField
            label="Address"
            name="address"
            variant="outlined"
            fullWidth
            value={shippingAddress.address}
            onChange={handleAddressChange}
            onBlur={handleBlur}
            error={!!errors.address}
            helperText={errors.address}
            sx={getFieldStyle(shippingAddress.address, errors.address)}
            InputLabelProps={{ sx: { color: '#ffffff' } }}
            InputProps={{ sx: { color: '#ffffff' } }}
          />
        </Grid>
        <Grid item xs={12} sm={10}>
          <TextField
            label="City"
            name="city"
            variant="outlined"
            fullWidth
            value={shippingAddress.city}
            onChange={handleAddressChange}
            onBlur={handleBlur}
            error={!!errors.city}
            helperText={errors.city}
            sx={getFieldStyle(shippingAddress.city, errors.city)}
            InputLabelProps={{ sx: { color: '#ffffff' } }}
            InputProps={{ sx: { color: '#ffffff' } }}
          />
        </Grid>
        {/* State dropdown in its own row */}
        <Grid item xs={12} sm={10}>
          <FormControl
            variant="outlined"
            fullWidth
            sx={{
              minWidth: '150px',
              ...getFieldStyle(shippingAddress.state, errors.state)
            }}
            error={!!errors.state}
          >
            <InputLabel sx={{ color: '#ffffff' }}>State</InputLabel>
            <Select
              name="state"
              value={shippingAddress.state}
              onChange={handleAddressChange}
              onBlur={handleBlur}
              label="State"
              sx={{ color: '#ffffff' }}
            >
              {usStates.map((stateAbbr) => (
                <MenuItem key={stateAbbr} value={stateAbbr}>
                  {stateAbbr}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {errors.state && (
            <Typography variant="caption" color="error">
              {errors.state}
            </Typography>
          )}
        </Grid>
        {/* ZIP Code in its own row */}
        <Grid item xs={12} sm={10}>
          <TextField
            label="ZIP Code"
            name="zip"
            variant="outlined"
            fullWidth
            value={shippingAddress.zip}
            onChange={handleAddressChange}
            onBlur={handleBlur}
            error={!!errors.zip}
            helperText={errors.zip}
            sx={getFieldStyle(shippingAddress.zip, errors.zip)}
            InputLabelProps={{ sx: { color: '#ffffff' } }}
            InputProps={{ sx: { color: '#ffffff' } }}
          />
        </Grid>
      </Grid>
      {/* Disclaimer moved below the Grid container */}
      <Box sx={{ textAlign: 'center', mt: 1 }}>
        <Typography variant="caption" sx={{ color: 'red' }}>
          *U.S. only
        </Typography>
      </Box>
    </Box>
  );
};

export default ShippingForm;