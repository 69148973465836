// src/App.js
import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Container, Box } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import '@fontsource/inter/700.css';
import { Typography } from '@mui/material';

import Header from './components/Header';
import DemoBox from './components/DemoBox';
import StickerOrderForm from './components/StickerOrderForm';
import ShippingForm from './components/ShippingForm';
import PaymentForm from './components/PaymentForm';

const theme = createTheme({
  palette: {
    primary: { main: '#9c27b0' },
    secondary: { main: '#ffeb3b' },
    background: { default: '#323232' },
    text: { primary: '#ffffff' }
  },
  typography: {
    fontFamily: "'Inter', sans-serif",
    allVariants: { color: '#ffffff' }
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          border: '1px solid #555555'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '8px'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: { color: '#ffffff' }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          '& fieldset': { borderColor: '#555555' },
          '&:hover fieldset': { borderColor: '#ffeb3b' },
          '&.Mui-focused': { 
            backgroundColor: '#323232'
          },
          '&.Mui-focused fieldset': { borderColor: '#9c27b0' },
          '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0px 1000px #323232 inset',
            WebkitTextFillColor: '#ffffff'
          }
        },
        input: { color: '#ffffff' }
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: '#323232',
          color: '#ffffff',
          border: '1px solid #555555'
        }
      }
    }
  }
});

const stripePromise = loadStripe('your-stripe-pk_live_key-here');

function App() {
  // Centralize the shipping details state in App so it can be passed to ShippingForm
  const [shippingAddress, setShippingAddress] = useState({
    name: '',
    email: '',
    address: '',
    city: '',
    state: '',
    zip: ''
  });

  // Handler for shipping address changes
  const handleAddressChange = (e) => {
    setShippingAddress((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  return (
    <ThemeProvider theme={theme}>
      <Elements stripe={stripePromise}>
        <Box sx={{ backgroundColor: 'background.default', minHeight: '100vh', py: 4 }}>
          <Container maxWidth="md">
            <Header />
            <Typography variant="h6" align="center" sx={{mb: 0.5, fontStyle: 'italic', color: '#ffffff'}}>
              Transform any picture into a beautiful sticker
            </Typography>
            <DemoBox />
            <StickerOrderForm />
            <ShippingForm
              shippingAddress={shippingAddress}
              handleAddressChange={handleAddressChange}
            />
            <PaymentForm amount={5000} />
          </Container>
        </Box>
      </Elements>
    </ThemeProvider>
  );
}

export default App;