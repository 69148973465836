// src/components/PaymentForm.js
import React, { useState } from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const PaymentForm = ({ amount }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [paymentError, setPaymentError] = useState('');

  const handlePayment = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) return;
    setLoading(true);

    const response = await fetch('/api/create-payment-intent', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ amount })
    });
    const { clientSecret } = await response.json();

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: { card: elements.getElement(CardElement) }
    });

    if (result.error) {
      setPaymentError(result.error.message);
    } else if (result.paymentIntent.status === 'succeeded') {
      alert('Payment successful! Your order is being processed.');
    }
    setLoading(false);
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Typography variant="h5" align="center" gutterBottom>
        Payment Details
      </Typography>
      <Box component="form" noValidate onSubmit={handlePayment} sx={{ mt: 2, textAlign: 'center' }}>
        <CardElement />
        {paymentError && (
          <Typography color="error" sx={{ mt: 1 }}>
            {paymentError}
          </Typography>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={!stripe || loading}
          sx={{ mt: 2 }}
        >
          {loading ? 'Processing...' : 'Pay'}
        </Button>
      </Box>
    </Container>
  );
};

export default PaymentForm;